import { HP_SET_ENV } from '../Types';

const HomePageReducerInitialState = {
	env: 'test'
};

export const HomePageReducer = (
	state = HomePageReducerInitialState,
	action
) => {
	const { type, payload } = action;
	switch (type) {
		case HP_SET_ENV: {
			const { env } = payload;

			return {
				env
			};
		}
		default:
			return state;
	}
};
