import React, { Suspense, useLayoutEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import FontFaceObserver from "fontfaceobserver"
import { NAVIGATION_ROUTES } from "./Navigation"
import { store } from "./RootRedux"
import "./styles/index.less"

const LoadApp = (props) => {
  const [Loaded, setLoaded] = useState(false)

  useLayoutEffect(() => {
    (async () => {
      const promises = []
      promises.push(loadFontAsync("Open Sans"))
      promises.push(loadFontAsync("Open Sans SemiBold"))
      promises.push(loadFontAsync("Shopee_font Bold"))
      promises.push(loadFontAsync("Shopee_font Medium"))
      promises.push(loadFontAsync("Shopee_font"))
      promises.push(loadFontAsync("Roboto Bold"))
      promises.push(loadFontAsync("Roboto Medium"))
      promises.push(loadFontAsync("Roboto Regular"))
      await Promise.all(promises)
      setLoaded(true)
    })()
  }, [])

  const loadFontAsync = (fontFamily) =>
    new Promise((res, rej) => {
      const font = new FontFaceObserver(fontFamily)
      font.load().then(res).catch(rej)
    })
  return Loaded ? props.children : null
}

ReactDOM.render(
  <LoadApp>
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<div />}>
            <Routes>
              {Object.values(NAVIGATION_ROUTES).map((route, idx) => (
                <Route key={idx} {...route} />
              ))}
            </Routes>
          </Suspense>
        </Router>
      </Provider>
    </React.StrictMode>
  </LoadApp>,
  document.getElementById("root")
)
