import { lazy } from "react";

const HomePage = lazy(() => import("src/Components/HomePage"));
const NoMatchPage = lazy(() => import("src/Components/NoMatch"));

export const NAVIGATION_ROUTES = Object.freeze({
  home: {
    path: "/",
    exact: true,
    element: <HomePage />,
  },
  noMatch: {
    path: "*",
    element: <NoMatchPage />,
  },
});
